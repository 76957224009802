<template>
  <router-view :key="$route.fullPath"/>
</template>

<style>

.animated {
  animation: gelatine 2.5s infinite;
}

@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  80% { transform: scale(1, 1); }
  85% { transform: scale(0.9, 1.1); }
  90% { transform: scale(1.1, 0.9); }
  95% { transform: scale(0.95, 1.05); }
}

p{
  font-size: 35px;
  font-weight: 400;
  line-height: 1.4;
}

div.container{
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.content{
  padding-top: 2em;
  padding-bottom: 8em;
  width: 100vw;
  max-width: 70em;
}

footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: smaller;
  color: lightgray;
}

#app {
  font-family: "Calibri",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1a1a1a;
}

body {
  margin: 0;
}


</style>
