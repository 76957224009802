
import { defineComponent } from 'vue'
import quiz from '../../public/content/quiz.json'

export default defineComponent({
  data() {
    let quizObj : any = quiz
    let lang : string = this.$route.params.lang.toString()
    let results = quizObj.languages[lang].results

    return {
      loading : false as boolean,
      lang : lang as string,
      results : results,
      text : null as string | null,
      image : null as string | null,
    }
  },
  methods : {

  },
  mounted() {

    let score : number = Number.parseInt(this.$route.params.score.toString())

    console.log(`score = ${score}`)

    for( let result of this.results ){
      if( score >= result.minScore ){
        this.text = result.text.replace(/{{\s*score\s*}}/g, score)
        this.image = result.image
      }
    }
  }
})
